* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body,
html {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
}

.App-main {
    flex: 1;
    padding: 20px;
    width: 100%;
}

.App-logo {
    height: 60px;
    pointer-events: none;
    margin-right: 20px;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    padding: 15px 20px;
    color: white;
    font-family: "Noto Sans JP", sans-serif;
    width: 100%;
}

.header-content {
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
}

.logo-container {
    flex-shrink: 0;
    margin-right: 20px;
}

.title-container {
    flex-grow: 1;
}

.App-header h1 {
    margin: 0 0 5px 0;
    font-size: 1.8em;
}

.App-header p {
    margin: 0;
    font-size: 1em;
}

.App-logo {
    height: 60px;
    pointer-events: none;
}

@media (max-width: 768px) {
    .header-content {
        flex-direction: column;
        align-items: flex-start;
    }

    .logo-container {
        margin-bottom: 10px;
    }

    .App-header h1 {
        font-size: 1.5em;
    }

    .App-header p {
        font-size: 0.9em;
    }

    .App-logo {
        height: 40px;
    }
}

.App-footer {
    background-color: #282c34;
    color: white;
    padding: 20px 0;
    font-family: "Noto Sans JP", sans-serif;
    width: 100%;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.App-footer p {
    margin: 0;
    justify-content: center;
}

.App-footer nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.App-footer nav ul li {
    margin-left: 20px;
}

.App-footer nav ul li a {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
}

.App-footer nav ul li a:hover {
    color: #61dafb;
}

@media (max-width: 600px) {
    .footer-content {
        flex-direction: column;
        text-align: center;
    }

    .App-footer nav ul {
        margin-top: 15px;
    }

    .App-footer nav ul li {
        margin: 0 10px;
    }
}
