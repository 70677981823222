.App {
  text-align: center;
}

.text-counter {
  position: relative;
  margin: 50px auto;
  width: 80%;
  max-width: 600px;
  min-width: 300px;
  height: auto;
}

.text-counter__textarea {
  font-size: 20px;
  width: 100%;
  border: none;
  border-bottom: 2px solid #ccc;
  padding: 5px 10px;
  background-color: transparent;
  outline: none;
  overflow: auto; /* スクロールバーを表示する */
  resize: none; /* ユーザーによるサイズ変更を無効にする */
  max-height: 400px; /* 最大高さを設定 */
}

textarea {
  font-size: 20px;
  width: 100%;
  height: 50%;
  max-height: 400px; /* 最大高さを400pxに設定 */
  min-height: 200px; /* 最小高さを200pxに設定 */
  border: none;
  border-bottom: 2px solid #ccc;
  padding: 5px 10px;
  background-color: transparent;
  outline: none;
}

p {
  font-size: 1.2em;
  font-weight: bold;
}

/* Small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .text-counter {
    width: 100%;
    min-width: auto;
  }

  textarea {
    font-size: 16px;
    min-height: 150px; /* 最小高さを150pxに設定 */
    max-height: 300px; /* 最大高さを300pxに設定 */
  }
}

/* Medium devices (tablets, 600px to 768px) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .text-counter {
    width: 90%;
    max-width: 500px;
  }

  textarea {
    font-size: 18px;
    min-height: 180px; /* 最小高さを180pxに設定 */
    max-height: 350px; /* 最大高さを350pxに設定 */
  }
}

/* Large devices (desktops, 768px and up) */
@media only screen and (min-width: 768px) {
  .text-counter {
    width: 80%;
    max-width: 600px;
    min-width: 300px;
  }

  textarea {
    font-size: 20px;
    min-height: 200px; /* 最小高さを200pxに設定 */
    max-height: 400px; /* 最大高さを400pxに設定 */
  }
}

.text-counter__results {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 30px;
}

.text-counter__card {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.text-counter__card:hover {
  transform: translateY(-5px);
}

.text-counter__card h3 {
  margin: 0 0 10px 0;
  font-size: 1em;
  color: #333;
}

.text-counter__card p {
  margin: 0;
  font-size: 1.5em;
  font-weight: bold;
  color: #4a90e2;
}

/* レスポンシブデザイン */
@media only screen and (max-width: 600px) {
  .text-counter__results {
    grid-template-columns: 1fr;
  }
}